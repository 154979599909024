function initPubSlider() {
    var sliders = document.querySelectorAll('.strate-footer-pub .th-slider');
    for (var i = 0; i != sliders.length; i++) {
        var slider = sliders[i];
        initSliderPub(slider);
    }

    function initSliderPub(slider) {
        // console.log("sliders", sliders);
        var parent = slider.parentNode;
        while (!parent.className.match(/th-slider-wrapper/)) {
            parent = parent.parentNode;
        }
        var navButtons = parent.querySelectorAll('.nav-buttons button');
        var dotsWrapper = parent.querySelectorAll('.nav-dots');

        var s = new thSlider(slider, {
            draggable: true,
            scrollListener: true,
            scrollModeMaxWidth: 1024,

            oninit: function (slider) {
                // console.log('scrollModeMaxWidth', this.scrollModeMaxWidth);
                console.log("navButtons", navButtons);
                console.log("dotsWrapper", dotsWrapper);
                if (navButtons.length === 2) {
                    thSliderTools.onInitNav(slider, navButtons);
                }

                if (dotsWrapper) {
                    thSliderTools.onInitDots(slider, dotsWrapper[0]);
                }
            },

            onchange: function (slider) {

                if (navButtons.length === 2) {
                    thSliderTools.onChangeNavIgnoreLastItemIn(slider, navButtons);
                }

                if (dotsWrapper) {
                    thSliderTools.onChangeDots(slider, dotsWrapper[0]);
                }
            }
        });

    }
}

initPubSlider();

