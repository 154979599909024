$(document).ready(function () {
    // Selectric est activé par défaut sur toutes les selects,
// mais nous on le veut pas avoir sur le sélecteur de langues en version tablette / mobile
    if (isTabletPortraitOrSmalller()) {
        $(".lang-select > select").selectric('destroy');
    }

    setTimeout(function () {
        var current_lang = document.getElementsByTagName('html')[0].getAttribute('lang');

        if (current_lang) {
            var selector = document.querySelector('#gtranslate_selector');

            selector.querySelectorAll('option').forEach(function (option) {
                if (option.value.endsWith(current_lang)) {
                    option.selected = true;
                    $('select').selectric('refresh');       // update de selectric en desktop
                }
            });
        }

    }, 2000);
});


