th_overlay.addCallbackOpen(function (overlayId) {
    if(overlayId == 'overlay-auteur') {

        var author_id = $('[data-author-id]').attr('data-author-id');
        load_overlay_author_data(author_id);
    }
});

function load_overlay_author_data(author_id) {
    $.ajax({
        url: '/wp-admin/admin-ajax.php',
        type: "GET",
        data: {
            'action': 'author_overlay',
            'author_id': author_id
        }
    }).done(function(response) {
        var content = $('#overlay-author-content');

        content.html(response);

        // Lazyload
        var lazyReLoad = new LazyLoad({
            elements_selector: ".lazy"
        });


        var sliders = document.querySelectorAll('#overlay-author-content .th-slider:not(.no-autoload)');
        for (i = 0; i != sliders.length; i++) {
            var slider = sliders[i];
            initSlider(slider);
        }
        function initSlider(slider) {
            var parent = slider.parentNode;
            while (!parent.className.match(/th-slider-wrapper/)) {
                parent = parent.parentNode;
            }

            var navButtons = parent.querySelectorAll('.nav-buttons button');
            var dotsWrapper = parent.querySelectorAll('.nav-dots');


            var reverse = 0;
            if (slider.className.match(/reverse/) && !isTabletPortraitOrSmalller()) {
                reverse = 1;
            }

            var s = new thSlider(slider, {
                draggable: true,
                rtl: reverse,
                scrollListener: true,
                scrollModeMaxWidth: '1199',
                oninit: function (slider) {

                    if (navButtons.length === 2) {
                        thSliderTools.onInitNav(slider, navButtons);
                    }

                    if (dotsWrapper) {
                        thSliderTools.onInitDots(slider, dotsWrapper[0]);
                    }

                },
                onchange: function (slider) {

                    if (navButtons.length > 0) {
                        thSliderTools.onChangeNavIgnoreLastItemIn(slider, navButtons);
                    }
                    if (dotsWrapper) {
                        thSliderTools.onChangeDots(slider, dotsWrapper[0]);
                    }
                }
            });

        }

    });
}