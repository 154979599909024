var blocCarteInteractive = {
    bloc: $(".bloc-carte-interactive"),
    slider: null,
    sliderWrapper: null,
    map: null,
    hotspots: null,
    slides: null,

    init: function () {
        // this.cards = $(".slide", this.bloc);
        this.map = this.bloc.find(".svg-canvas");
        this.hotspots = this.map.find("#map-inte path");
        this.slides = this.bloc.find(".element");

        this.initSlider();
    },

    initSlider: function () {

        if (this.bloc) {
            var sliderWrapper = this.bloc.find(".th-slider")[0];
            var _self = this;
            var navButtons = this.bloc.find(".nav-buttons button");


            if (sliderWrapper) {

                this.slider = new thSlider(sliderWrapper, {
                    draggable: true,
                    scrollListener: true,
                    scrollModeMaxWidth: 1199,

                    oninit: function (sliderWrapper) {
                        if (navButtons.length === 2) {
                            thSliderTools.onInitNav(sliderWrapper, navButtons);
                        }


                        $.each(_self.hotspots, function () {
                            var pathId = $(this).attr('data-name');
                            var from = 'click'; //Histoire de pas avoir une boucle infinie on vérifie la provenance de l'activation de la zone

                            $(this).click(function() {
                                _self.activeZone(pathId, from);
                            });
                        });

                        // Active le 1er hotspot à l'initialisation

                    },
                    beforechange: function (sliderWrapper, index) {
                        var pathId = $(_self.slides[index]).attr('data-name');
                        var from = 'slider'; //Histoire de pas avoir une boucle infinie on vérifie la provenance de l'activation de la zone
                        _self.activeZone(pathId, from);
                    }
                });


                //Initialisation
                $.each(_self.slides, function () {
                    var pathId = $(this).attr('data-name');
                    var from = 'init'; //Histoire de pas avoir une boucle infinie on vérifie la provenance de l'activation de la zone

                    //On init la première zone qu'on croise dans le slider en zone active
                    _self.activeZone(pathId, from);

                    return false;
                });
            }
        }
    },

    // Zone en état hover et actif
    activeZone: function (pathId, from) {
        this.hotspots.removeClass('is-active');
        var path = this.map.find('[data-name="' + pathId+'"');
        path.addClass('is-active');

        //Histoire de pas avoir une boucle infinie on vérifie la provenance de l'activation de la zone
        if(from == 'click'){
            this.goToCard(pathId);
        }
    },


    goToCard: function (zone) {
        var singleCard = $('.element[data-name="' + zone + '"]');
        if(singleCard.length > 0){
            this.slider.goto(singleCard.index());
        }
    },

}

blocCarteInteractive.init();
