// OVERLAY MAP
function callbackMapPageFavoris(macarte) {
    macarte.zoomControl.setPosition('topright');

    /* A l'ouverture de l'overlay, on recharge la carte */
    th_overlay.addCallbackOpen(function (overlayId) {
        if (overlayId == 'overlay-favoris') {
            setTimeout(function () {
                th_favoris._map.map.invalidateSize();

                if (th_favoris._map.markerBounds && Object.keys(th_favoris._map.markerBounds).length > 0 && th_favoris._map.markerBounds.constructor === Object) {
                    th_favoris._map.map.fitBounds(th_favoris._map.markerBounds, {padding: [25, 25]});

                    if (Object.keys(th_favoris._map.markers).length == 1) {
                        th_favoris._map.map.setZoom(15);
                    }
                    zoom = th_favoris._map.map.getZoom();
                    if (zoom >= 16) {
                        th_favoris._map.map.setZoom(15);
                    }

                }
            }, 250);
        }
    })
}