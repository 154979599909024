var menuPrincipal = {
    overlay: $('#overlay-menu'),
    wrapper: $('.wrapper', this.overlay), //Wrapper du menu utilisé pour cacher un élément en mobile qui pose problème sur iphone
    menu: $('.menu', this.overlay), //Menu principal pour faciliter le ciblage
    menuItem: $('.sub-menu', this.menu).siblings('a'), //Les items de niveau 1 cliquable qui ouvrent les sous-menu
    retour: $('.retour', this.menu), //Bouton retour pour fermer les sous-menu

    init: function(){
        this.listener();

    },

    listener: function(){
        var _this = this;

        _this.menuItem.on('click', function(e){
            e.preventDefault();
            _this.toggleSubMenu($(this));
        });

        _this.retour.on('click', function(){
            _this.closeAll();
        });

        _this.openFirst(_this);

    },

    toggleSubMenu: function(_this){
        console.log('toggleSubMenu');
        if (_this.parent().hasClass('active')){
            this.closeAll();
        }else{
            this.wrapper.addClass('open');
            this.menu.addClass('sub-open');
            this.menuItem.parent().removeClass('active');
            _this.parent().addClass('active');
        }
    },

    closeAll: function(){
        this.wrapper.removeClass('open');
        this.menu.removeClass('sub-open');
        this.menuItem.parent().removeClass('active');
    },

    //A l'ouverture du menu, on ouvre le premier menu si il a un sous-menu
    openFirst: function(_this){

        if(!isTouchDevice()){
            th_overlay.addCallbackOpen(function (overlayId) {
                if (overlayId == 'overlay-menu') {
                    setTimeout(function () {
                        //Si le premier item a un sous menu
                        if(_this.menu.find('>ul>li:first-child:not(.active)>.sub-menu').length > 0 && !_this.menu.hasClass('sub-open')){
                            _this.menu.find('>ul>li:first-child>a').click();
                        }
                    }, 250);
                }
            });
        }

    }
}
menuPrincipal.init();



/* Initialisation du slider */
(function () {

    var sliders = document.querySelectorAll('.overlay-menu .th-slider:not(.no-autoload)');
    for (i = 0; i != sliders.length; i++) {
        var slider = sliders[i];
        initSlider(slider);
    }


    function initSlider(slider) {
        var parent = slider.parentNode;
        while (!parent.className.match(/th-slider-wrapper/)) {
            parent = parent.parentNode;
        }

        var navButtons = parent.querySelectorAll('.nav-buttons button');
        var dotsWrapper = parent.querySelectorAll('.nav-dots');


        var reverse = 0;
        if (slider.className.match(/reverse/) && !isTabletPortraitOrSmalller()) {
            reverse = 1;
        }

        var s = new thSlider(slider, {
            draggable: true,
            rtl: reverse,
            scrollListener: true,
            oninit: function (slider) {

                if (navButtons.length === 2) {
                    thSliderTools.onInitNav(slider, navButtons);
                }

                if (dotsWrapper) {
                    thSliderTools.onInitDots(slider, dotsWrapper[0]);
                }

            },
            onchange: function (slider) {

                if (navButtons.length > 0) {
                    thSliderTools.onChangeNavIgnoreLastItemIn(slider, navButtons);
                }
                if (dotsWrapper) {
                    thSliderTools.onChangeDots(slider, dotsWrapper[0]);
                }
            }
        });

    }

})();