$(document).ready(function () {
    var coverHomeSlider = $('.container-slick-slider');

    if (coverHomeSlider) {
        var slideSpeed = coverHomeSlider.attr("data-time-slide");
        coverHomeSlider.slick(
            {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: false,
                autoplay: true,
                autoplaySpeed: slideSpeed,
                pauseOnHover:false,
                dots: true,
                appendDots: $('.slick-nav-dots'),
                appendArrows: $('.slick-nav-arrows'),
                prevArrow: "<button class='btn-square'></button>",
                nextArrow: "<button class='btn-square'></button>",
                customPaging: function (slider, i) {
                    var thumb = $(slider.$slides[i]).data();
                    return '<a>' + (i + 1) + '</a>';
                }
            }
        );
    }
});