/**
 * ======================================================================================================================================================
 * ======================================================================================================================================================
 * ====================================================================== SERVICES ======================================================================
 * ======================================================================================================================================================
 * ======================================================================================================================================================
 * */
// clarity
tarteaucitron.services.th_clarity = {
    "key": "th_clarity",
    "type": "analytic",
    "name": "Clarity",
    "uri": "https://clarity.microsoft.com/",
    "needConsent": true,
    "cookies": [],
    "js": function () {
        "use strict";
        return;
    }
};

// clarity
tarteaucitron.services.clarity = {
    "key": "clarity",
    "type": "analytic",
    "name": "Clarity",
    "uri": "https://clarity.microsoft.com/",
    "needConsent": true,
    "cookies": [],
    "js": function () {
        "use strict";

        window["clarity"] = window["clarity"] || function () { (window["clarity"].q = window["clarity"].q || []).push(arguments) };

        tarteaucitron.addScript('https://www.clarity.ms/tag/' + tarteaucitron.user.clarity);
    }
};

// generic iframe
tarteaucitron.services.th_iframe = {
    "key": "th_iframe",
    "type": "other",
    "name": "Web content",
    "uri": "",
    "needConsent": true,
    "cookies": [],
    "js": function () {
        "use strict";
        return;
    },
    "fallback": function () {
        "use strict";
        return;
    }
};

// amplitude
tarteaucitron.services.th_amplitude = {
    "key": "th_amplitude",
    "type": "analytic",
    "name": "Amplitude",
    "uri": "https://amplitude.com/privacy",
    "needConsent": true,
    "cookies": [],
    "js": function () {
        "use strict";
        return;
    }
};

// abtasty
tarteaucitron.services.th_abtasty = {
    "key": "th_abtasty",
    "type": "api",
    "name": "ABTasty",
    "uri": "https://www.abtasty.com/terms-of-use/",
    "needConsent": true,
    "cookies": ['ABTasty', 'ABTastySession'],
    "js": function () {
        "use strict";
        return;
    }
};


// yandex metrica
tarteaucitron.services.th_metrica = {
    "key": "th_metrica",
    "type": "analytic",
    "name": "Yandex Metrica",
    "uri": "https://yandex.com/legal/confidential/",
    "needConsent": true,
    "cookies": ['_ym_metrika_enabled', '_ym_isad', '_ym_uid', '_ym_d', 'yabs-sid', '_ym_debug', '_ym_mp2_substs', '_ym_hostIndex', '_ym_mp2_track', 'yandexuid', 'usst'],
    "js": function () {
        "use strict";
        return;
    }
};

// addthis
tarteaucitron.services.th_addthis = {
    "key": "th_addthis",
    "type": "social",
    "name": "AddThis",
    "uri": "https://www.addthis.com/privacy/privacy-policy#publisher-visitors",
    "needConsent": true,
    "cookies": ['__atuvc', '__atuvs'],
    "js": function () {
        "use strict";
        return;
    },
    "fallback": function () {
        "use strict";
        return;
    }
};

// addtoanyfeed
tarteaucitron.services.th_addtoanyfeed = {
    "key": "th_addtoanyfeed",
    "type": "social",
    "name": "AddToAny (feed)",
    "uri": "https://www.addtoany.com/privacy",
    "needConsent": true,
    "cookies": [],
    "js": function () {
        "use strict";
        return;
    },
    "fallback": function () {
        "use strict";
        return;
    }
};

// addtoanyshare
tarteaucitron.services.th_addtoanyshare = {
    "key": "th_addtoanyshare",
    "type": "social",
    "name": "AddToAny (share)",
    "uri": "https://www.addtoany.com/privacy",
    "needConsent": true,
    "cookies": [],
    "js": function () {
        "use strict";
        return;
    },
    "fallback": function () {
        "use strict";
        return;
    }
};

// aduptech ads
tarteaucitron.services.th_aduptech_ads = {
    "key": "th_aduptech_ads",
    "type": "ads",
    "name": "Ad Up Technology (ads)",
    "uri": "https://www.adup-tech.com/datenschutz",
    "needConsent": true,
    "cookies": [],
    "js": function () {
        "use strict";

        return;

    },
    "fallback": function () {
        "use strict";
        return;
    }
};

// aduptech conversion
tarteaucitron.services.th_aduptech_conversion = {
    "key": "th_aduptech_conversion",
    "type": "ads",
    "name": "Ad Up Technology (conversion)",
    "uri": "https://www.adup-tech.com/datenschutz",
    "needConsent": true,
    "cookies": [],
    "js": function () {
        "use strict";

        return;
    }
};

// aduptech retargeting
tarteaucitron.services.th_aduptech_retargeting = {
    "key": "th_aduptech_retargeting",
    "type": "ads",
    "name": "Ad Up Technology (retargeting)",
    "uri": "https://www.adup-tech.com/datenschutz",
    "needConsent": true,
    "cookies": [],
    "js": function () {
        "use strict";

        return;
    }
};

// alexa
tarteaucitron.services.th_alexa = {
    "key": "th_alexa",
    "type": "analytic",
    "name": "Alexa",
    "uri": "https://www.alexa.com/help/privacy",
    "needConsent": true,
    "cookies": ['__asc', '__auc'],
    "js": function () {
        "use strict";
        return;
    }
};

// amazon
tarteaucitron.services.th_amazon = {
    "key": "th_amazon",
    "type": "ads",
    "name": "Amazon",
    "uri": "https://www.amazon.com/gp/help/customer/display.html/ref=help_search_1-1?ie=UTF8&nodeId=201909010&qid=1544617177&sr=1-1",
    "needConsent": true,
    "cookies": [],
    "js": function () {
        "use strict";
        return;
    },
    "fallback": function () {
        "use strict";
        var id = 'amazon';
        tarteaucitron.fallback(['amazon_product'], tarteaucitron.engage(id));
    }
};

// calameo
tarteaucitron.services.th_calameo = {
    "key": "th_calameo",
    "type": "video",
    "name": "Calameo",
    "uri": "https://fr.calameo.com/privacy",
    "needConsent": true,
    "cookies": [],
    "js": function () {
        "use strict";
        return;
    },
    "fallback": function () {
        "use strict";
        var id = 'calameo';
        tarteaucitron.fallback(['calameo-canvas'], function (elem) {
            elem.style.width = elem.getAttribute('width') + 'px';
            elem.style.height = elem.getAttribute('height') + 'px';
            return tarteaucitron.engage(id);
        });
    }
};

// clicky
tarteaucitron.services.th_clicky = {
    "key": "th_clicky",
    "type": "analytic",
    "name": "Clicky",
    "uri": "https://clicky.com/terms",
    "needConsent": true,
    "cookies": ['_jsuid', '_eventqueue', '_referrer_og', '_utm_og', '_first_pageview', 'clicky_olark', 'no_trackyy_' + tarteaucitron.user.clickyId, 'unpoco_' + tarteaucitron.user.clickyId, 'heatmaps_g2g_' + tarteaucitron.user.clickyId],
    "js": function () {
        "use strict";
        return;
    },
};

// clicmanager
tarteaucitron.services.th_clicmanager = {
    "key": "th_clicmanager",
    "type": "ads",
    "name": "Clicmanager",
    "uri": "http://www.clicmanager.fr/infos_legales.php",
    "needConsent": true,
    "cookies": [],
    "js": function () {
        "use strict";
        return;
    },
    "fallback": function () {
        "use strict";
        return;
    }
};

// contentsquare
tarteaucitron.services.th_contentsquare = {
    "key": "th_contentsquare",
    "type": "api",
    "name": "ContentSquare",
    "uri": "https://docs.contentsquare.com/uxa-en/#collected-data",
    "needConsent": true,
    "cookies": ['_cs_id', '_cs_s', '_cs_vars', '_cs_ex', '_cs_c', '_cs_optout'],
    "js": function () {
        "use strict";
        return;
    },
};

// crazyegg
tarteaucitron.services.th_crazyegg = {
    "key": "th_crazyegg",
    "type": "analytic",
    "name": "Crazy Egg",
    "uri": "https://www.crazyegg.com/privacy",
    "needConsent": true,
    "cookies": [],
    "js": function () {
        "use strict";
        return;
    },
};

// criteo
tarteaucitron.services.th_criteo = {
    "key": "th_criteo",
    "type": "ads",
    "name": "Criteo",
    "uri": "http://www.criteo.com/privacy/",
    "needConsent": true,
    "cookies": [],
    "js": function () {
        "use strict";
        return;
    },
    "fallback": function () {
        "use strict";
        return;
    }
};

// dailymotion
tarteaucitron.services.th_dailymotion = {
    "key": "th_dailymotion",
    "type": "video",
    "name": "Dailymotion",
    "uri": "https://www.dailymotion.com/legal/privacy",
    "needConsent": true,
    "cookies": ['ts', 'dmvk', 'hist', 'v1st', 's_vi'],
    "js": function () {
        "use strict";
        return;
    },
    "fallback": function () {
        "use strict";
        return;
    }
};

// dating affiliation
tarteaucitron.services.th_datingaffiliation = {
    "key": "th_datingaffiliation",
    "type": "ads",
    "name": "Dating Affiliation",
    "uri": "http://www.dating-affiliation.com/conditions-generales.php",
    "needConsent": true,
    "cookies": [],
    "js": function () {
        "use strict";
        return;
    },
    "fallback": function () {
        "use strict";
        return;
    }
};

// dating affiliation popup
tarteaucitron.services.th_datingaffiliationpopup = {
    "key": "th_datingaffiliationpopup",
    "type": "ads",
    "name": "Dating Affiliation (Pop Up)",
    "uri": "http://www.dating-affiliation.com/conditions-generales.php",
    "needConsent": true,
    "cookies": ['__utma', '__utmb', '__utmc', '__utmt_Tools', '__utmv', '__utmz', '_ga', '_gat', '_gat_UA-65072040-17', '__da-pu-xflirt-ID-pc-o169'],
    "js": function () {
        "use strict";
        return;
    },
    "fallback": function () {
        "use strict";
        return;
    }
};

// leadforensics
tarteaucitron.services.th_leadforensics = {
    "key": "th_leadforensics",
    "type": "analytic",
    "name": "LeadForensics",
    "uri": "https://www.leadforensics.com/privacy-policy/",
    "needConsent": true,
    "cookies": ['trackalyzer'],
    "js": function () {
        "use strict";
        return;
    },
};

// disqus
tarteaucitron.services.th_disqus = {
    "key": "th_disqus",
    "type": "comment",
    "name": "Disqus",
    "uri": "https://help.disqus.com/customer/portal/articles/466259-privacy-policy",
    "needConsent": true,
    "cookies": [],
    "js": function () {
        "use strict";
        return;
    },
    "fallback": function () {
        "use strict";
        return;
    }
};

// ekomi
tarteaucitron.services.th_ekomi = {
    "key": "th_ekomi",
    "type": "social",
    "name": "eKomi",
    "uri": "http://www.ekomi-us.com/us/privacy/",
    "needConsent": true,
    "cookies": [],
    "js": function () {
        "use strict";
        return;
    },
};

// etracker
tarteaucitron.services.th_etracker = {
    "key": "th_etracker",
    "type": "analytic",
    "name": "eTracker",
    "uri": "https://www.etracker.com/en/data-protection.html",
    "needConsent": true,
    "cookies": [],
    "js": function () {
        "use strict";
        return;
    },
};

// facebook
tarteaucitron.services.th_facebook = {
    "key": "th_facebook",
    "type": "social",
    "name": "Facebook",
    "uri": "https://www.facebook.com/policies/cookies/",
    "needConsent": true,
    "cookies": [],
    "js": function () {
        "use strict";
        return;
    },
    "fallback": function () {
        "use strict";
        return;
    }
};

// facebooklikebox
tarteaucitron.services.th_facebooklikebox = {
    "key": "th_facebooklikebox",
    "type": "social",
    "name": "Facebook (like box)",
    "uri": "https://www.facebook.com/policies/cookies/",
    "needConsent": true,
    "cookies": [],
    "js": function () {
        "use strict";
        return;
    },
    "fallback": function () {
        "use strict";
        return;
    }
};

// facebookcomment
tarteaucitron.services.th_facebookcomment = {
    "key": "th_facebookcomment",
    "type": "comment",
    "name": "Facebook (commentaire)",
    "uri": "https://www.facebook.com/policies/cookies/",
    "needConsent": true,
    "cookies": [],
    "js": function () {
        "use strict";
        return;
    },
    "fallback": function () {
        "use strict";
        return;
    }
};

// ferank
tarteaucitron.services.th_ferank = {
    "key": "th_ferank",
    "type": "analytic",
    "name": "FERank",
    "uri": "https://www.ferank.fr/respect-vie-privee/#mesureaudience",
    "needConsent": false,
    "cookies": [],
    "js": function () {
        "use strict";
        return;
    },
};

// ferank pub
tarteaucitron.services.th_ferankpub = {
    "key": "th_ferankpub",
    "type": "ads",
    "name": "FERank (pub)",
    "uri": "https://www.ferank.fr/respect-vie-privee/#regiepublicitaire",
    "needConsent": false,
    "cookies": [],
    "js": function () {
        "use strict";
        return;
    },
    "fallback": function () {
        "use strict";
        return;
    }
};

// get+
tarteaucitron.services.th_getplus = {
    "key": "th_getplus",
    "type": "analytic",
    "name": "Get+",
    "uri": "http://www.getplus.fr/Conditions-generales-de-vente_a226.html",
    "needConsent": true,
    "cookies": ['_first_pageview', '_jsuid', 'no_trackyy_' + tarteaucitron.user.getplusId, '_eventqueue'],
    "js": function () {
        "use strict";
        return;
    },
};

// google+
tarteaucitron.services.th_gplus = {
    "key": "th_gplus",
    "type": "social",
    "name": "Google+",
    "uri": "https://policies.google.com/privacy",
    "needConsent": true,
    "cookies": [],
    "js": function () {
        "use strict";
        return;
    },
    "fallback": function () {
        "use strict";
        return;
    }
};

// google+ badge
tarteaucitron.services.th_gplusbadge = {
    "key": "th_gplusbadge",
    "type": "social",
    "name": "Google+ (badge)",
    "uri": "https://policies.google.com/privacy",
    "needConsent": true,
    "cookies": [],
    "js": function () {
        "use strict";
        return;
    },
    "fallback": function () {
        "use strict";
        return;
    }
};

// google adsense
tarteaucitron.services.th_adsense = {
    "key": "th_adsense",
    "type": "ads",
    "name": "Google Adsense",
    "uri": "https://adssettings.google.com/",
    "needConsent": true,
    "cookies": [],
    "js": function () {
        "use strict";
        return;
    },
    "fallback": function () {
        "use strict";
        return;
    }
};

// google partners badge
tarteaucitron.services.th_googlepartners = {
    "key": "th_googlepartners",
    "type": "ads",
    "name": "Google Partners Badge",
    "uri": "https://adssettings.google.com/",
    "needConsent": true,
    "cookies": [],
    "js": function () {
        "use strict";
        return;
    },
    "fallback": function () {
        "use strict";
        return;
    }
};

// google adsense search (form)
tarteaucitron.services.th_adsensesearchform = {
    "key": "th_adsensesearchform",
    "type": "ads",
    "name": "Google Adsense Search (form)",
    "uri": "https://adssettings.google.com/",
    "needConsent": true,
    "cookies": [],
    "js": function () {
        "use strict";
        return;
    },
};

// google adsense search (result)
tarteaucitron.services.th_adsensesearchresult = {
    "key": "th_adsensesearchresult",
    "type": "ads",
    "name": "Google Adsense Search (result)",
    "uri": "https://adssettings.google.com/",
    "needConsent": true,
    "cookies": [],
    "js": function () {
        "use strict";
        return;
    },
    "fallback": function () {
        "use strict";
        return;
    }
};

// googleadwordsconversion
tarteaucitron.services.th_googleadwordsconversion = {
    "key": "th_googleadwordsconversion",
    "type": "ads",
    "name": "Google Adwords (conversion)",
    "uri": "https://www.google.com/settings/ads",
    "needConsent": true,
    "cookies": [],
    "js": function () {
        "use strict";
        return;
    },
};

// googleadwordsremarketing
tarteaucitron.services.th_googleadwordsremarketing = {
    "key": "th_googleadwordsremarketing",
    "type": "ads",
    "name": "Google Adwords (remarketing)",
    "uri": "https://www.google.com/settings/ads",
    "needConsent": true,
    "cookies": [],
    "js": function () {
        "use strict";
        return;
    },
};

// google analytics (old)
tarteaucitron.services.th_gajs = {
    "key": "th_gajs",
    "type": "analytic",
    "name": "Google Analytics (ga.js)",
    "uri": "https://support.google.com/analytics/answer/6004245",
    "needConsent": true,
    "cookies": ['_ga', '_gat', '__utma', '__utmb', '__utmc', '__utmt', '__utmz'],
    "js": function () {
        "use strict";
        return;
    },
};

// google analytics
tarteaucitron.services.th_analytics = {
    "key": "th_analytics",
    "type": "analytic",
    "name": "Google Analytics (universal)",
    "uri": "https://support.google.com/analytics/answer/6004245",
    "needConsent": true,
    "cookies": ['_ga', '_gat', '_gid', '__utma', '__utmb', '__utmc', '__utmt', '__utmz'],
    "js": function () {
        "use strict";
        return;
    },
};

// google analytics
tarteaucitron.services.th_gtag = {
    "key": "th_gtag",
    "type": "analytic",
    "name": "Google Analytics (gtag.js)",
    "uri": "https://support.google.com/analytics/answer/6004245",
    "needConsent": true,
    "cookies": (function () {
        // Add _gat_gtag_UA_XXXXXXX_XX cookie to cookies array
        var gatGtagUaCookie = '_gat_gtag_' + tarteaucitron.user.gtagUa;
        gatGtagUaCookie = gatGtagUaCookie.replace(/-/g, '_');
        return ['_ga', '_gat', '_gid', '__utma', '__utmb', '__utmc', '__utmt', '__utmz', gatGtagUaCookie];
    })(),
    "js": function () {
        "use strict";
        return;
    },
};

// google maps
tarteaucitron.services.th_googlemaps = {
    "key": "th_googlemaps",
    "type": "api",
    "name": "Google Maps",
    "uri": "https://adssettings.google.com/",
    "needConsent": true,
    "cookies": [],
    "js": function () {
        "use strict";
        return;
    },
    "fallback": function () {
        "use strict";
        return;
    }
};

// googlemaps search
tarteaucitron.services.th_googlemapssearch = {
    "key": "th_googlemapssearch",
    "type": "api",
    "name": "Google Maps Search API",
    "uri": "https://adssettings.google.com/",
    "needConsent": true,
    "cookies": ['nid'],
    "js": function () {
        "use strict";
        return;
    },
    "fallback": function () {
        "use strict";
        return;
    }
};

// googlemaps embed iframe
tarteaucitron.services.th_googlemapsembed = {
    "key": "th_googlemapsembed",
    "type": "api",
    "name": "Google Maps Embed",
    "uri": "https://adssettings.google.com/",
    "needConsent": true,
    "cookies": ['apisid', 'hsid', 'nid', 'sapisid', 'sid', 'sidcc', 'ssid', '1p_jar'],
    "js": function () {
        "use strict";
        return;
    },
    "fallback": function () {
        "use strict";
        return;
    }
};

// googlemaps search
tarteaucitron.services.th_googlemapssearch = {
    "key": "th_googlemapssearch",
    "type": "api",
    "name": "Google Tag Manager",
    "uri": "https://adssettings.google.com/",
    "needConsent": true,
    "cookies": ['nid'],
    "js": function () {
        "use strict";
        return;
    },
    "fallback": function () {
        "use strict";
        return;
    }
};

// google tag manager multiple
tarteaucitron.services.th_multiplegoogletagmanager = {
    "key": "th_multiplegoogletagmanager",
    "type": "api",
    "name": "Google Tag Manager",
    "uri": "https://adssettings.google.com/",
    "needConsent": true,
    "cookies": ['_ga', '_gat', '__utma', '__utmb', '__utmc', '__utmt', '__utmz', '__gads', '_drt_', 'FLC', 'exchange_uid', 'id', 'fc', 'rrs', 'rds', 'rv', 'uid', 'UIDR', 'UID', 'clid', 'ipinfo', 'acs'],
    "js": function () {
        "use strict";
        return;
    },
};

// google webfonts
tarteaucitron.services.th_googlefonts = {
    "key": "th_googlefonts",
    "type": "api",
    "name": "Google Webfonts",
    "uri": "https://www.google.com/intl/de/policies/privacy/",
    "needConsent": true,
    "cookies": [],
    "js": function () {
        "use strict";
        return;
    },
};

// hubspot
tarteaucitron.services.th_hubspot = {
    "key": "th_hubspot",
    "type": "analytic",
    "name": "Hubspot",
    "uri": "https://legal.hubspot.com/privacy-policy",
    "needConsent": true,
    "cookies": ['hubspotutk', 'fr', '__hstc', '__hssrc', '__hssc', '__cfduid'],
    "js": function () {
        "use strict";
        return;
    },
};

// googlemaps embed iframe
tarteaucitron.services.th_googlemapsembed = {
    "key": "th_googlemapsembed",
    "type": "api",
    "name": "Google jsapi",
    "uri": "https://policies.google.com/privacy",
    "needConsent": true,
    "cookies": ['apisid', 'hsid', 'nid', 'sapisid', 'sid', 'sidcc', 'ssid', '1p_jar'],
    "js": function () {
        "use strict";
        return;
    },
    "fallback": function () {
        "use strict";
        return;
    }
};

// google tag manager
tarteaucitron.services.th_googletagmanager = {
    "key": "th_googletagmanager",
    "type": "api",
    "name": "Google Tag Manager",
    "uri": "https://adssettings.google.com/",
    "needConsent": true,
    "cookies": ['_ga', '_gat', '__utma', '__utmb', '__utmc', '__utmt', '__utmz', '__gads', '_drt_', 'FLC', 'exchange_uid', 'id', 'fc', 'rrs', 'rds', 'rv', 'uid', 'UIDR', 'UID', 'clid', 'ipinfo', 'acs'],
    "js": function () {
        "use strict";
        return;
    },
};

// google tag manager multiple
tarteaucitron.services.th_multiplegoogletagmanager = {
    "key": "th_multiplegoogletagmanager",
    "type": "api",
    "name": "Google Tag Manager",
    "uri": "https://adssettings.google.com/",
    "needConsent": true,
    "cookies": ['_ga', '_gat', '__utma', '__utmb', '__utmc', '__utmt', '__utmz', '__gads', '_drt_', 'FLC', 'exchange_uid', 'id', 'fc', 'rrs', 'rds', 'rv', 'uid', 'UIDR', 'UID', 'clid', 'ipinfo', 'acs'],
    "js": function () {
        "use strict";
        return;
    },
};

// google webfonts
tarteaucitron.services.th_googlefonts = {
    "key": "th_googlefonts",
    "type": "api",
    "name": "Google Webfonts",
    "uri": "https://www.google.com/intl/de/policies/privacy/",
    "needConsent": true,
    "cookies": [],
    "js": function () {
        "use strict";
        return;
    },
};

// hubspot
tarteaucitron.services.th_hubspot = {
    "key": "th_hubspot",
    "type": "analytic",
    "name": "Hubspot",
    "uri": "https://legal.hubspot.com/privacy-policy",
    "needConsent": true,
    "cookies": ['hubspotutk', 'fr', '__hstc', '__hssrc', '__hssc', '__cfduid'],
    "js": function () {
        "use strict";
        return;
    },
};

// jsapi
tarteaucitron.services.th_jsapi = {
    "key": "th_jsapi",
    "type": "api",
    "name": "Google jsapi",
    "uri": "https://policies.google.com/privacy",
    "needConsent": true,
    "cookies": [],
    "js": function () {
        "use strict";
        return;
    },
};

// twitterwidgetsapi
tarteaucitron.services.th_twitterwidgetsapi = {
    "key": "th_twitterwidgetsapi",
    "type": "api",
    "name": "Twitter Widgets API",
    "uri": "https://support.twitter.com/articles/20170514",
    "needConsent": true,
    "cookies": [],
    "js": function () {
        "use strict";
        return;
    },
    "fallback": function () {
        "use strict";
        return;
    }
};

// recaptcha
tarteaucitron.services.th_recaptcha = {
    "key": "th_recaptcha",
    "type": "api",
    "name": "reCAPTCHA",
    "uri": "https://policies.google.com/privacy",
    "needConsent": true,
    "cookies": ['nid'],
    "js": function () {
        "use strict";
        return;
    },
    "fallback": function () {
        "use strict";
        return;
    }
};

// twitterwidgetsapi
tarteaucitron.services.th_twitterwidgetsapi = {
    "key": "th_twitterwidgetsapi",
    "type": "api",
    "name": "Twitter Widgets API",
    "uri": "https://support.twitter.com/articles/20170514",
    "needConsent": true,
    "cookies": [],
    "js": function () {
        "use strict";
        return;
    },
    "fallback": function () {
        "use strict";
        return;
    }
};

// recaptcha
tarteaucitron.services.th_recaptcha = {
    "key": "th_recaptcha",
    "type": "api",
    "name": "reCAPTCHA",
    "uri": "https://policies.google.com/privacy",
    "needConsent": true,
    "cookies": ['nid'],
    "js": function () {
        "use strict";
        return;
    },
    "fallback": function () {
        "use strict";
        return;
    }
};

// linkedin
tarteaucitron.services.th_linkedin = {
    "key": "th_linkedin",
    "type": "social",
    "name": "Linkedin",
    "uri": "https://www.linkedin.com/legal/cookie_policy",
    "needConsent": true,
    "cookies": [],
    "js": function () {
        "use strict";
        return;
    },
    "fallback": function () {
        "use strict";
        return;
    }
};

// mautic
tarteaucitron.services.th_mautic = {
    "key": "th_mautic",
    "type": "analytic",
    "name": "Mautic",
    "uri": "https://www.mautic.org/privacy-policy/",
    "needConsent": true,
    "cookies": ['mtc_id', 'mtc_sid'],
    "js": function () {
        "use strict";
        return;
    },
    "fallback": function () {
        "use strict";
        return;
    }
};

// microsoftcampaignanalytics
tarteaucitron.services.th_microsoftcampaignanalytics = {
    "key": "th_microsoftcampaignanalytics",
    "type": "analytic",
    "name": "Microsoft Campaign Analytics",
    "uri": "https://privacy.microsoft.com/privacystatement/",
    "needConsent": true,
    "cookies": [],
    "js": function () {
        "use strict";
        return;
    },
    "fallback": function () {
        "use strict";
        return;
    }
};

// pinterest
tarteaucitron.services.th_pinterest = {
    "key": "th_pinterest",
    "type": "social",
    "name": "Pinterest",
    "uri": "https://about.pinterest.com/privacy-policy",
    "needConsent": true,
    "cookies": [],
    "js": function () {
        "use strict";
        return;
    },
    "fallback": function () {
        "use strict";
        return;
    }
};

// prelinker
tarteaucitron.services.th_prelinker = {
    "key": "th_prelinker",
    "type": "ads",
    "name": "Prelinker",
    "uri": "http://www.prelinker.com/index/index/cgu/",
    "needConsent": true,
    "cookies": ['_sp_id.32f5', '_sp_ses.32f5'],
    "js": function () {
        "use strict";
        return;
    },
    "fallback": function () {
        "use strict";
        return;
    }
};

// prezi
tarteaucitron.services.th_prezi = {
    "key": "th_prezi",
    "type": "video",
    "name": "Prezi",
    "uri": "https://prezi.com/privacy-policy/",
    "needConsent": true,
    "cookies": [],
    "js": function () {
        "use strict";
        return;
    },
    "fallback": function () {
        "use strict";
        return;
    }
};

// pubdirecte
tarteaucitron.services.th_pubdirecte = {
    "key": "th_pubdirecte",
    "type": "ads",
    "name": "Pubdirecte",
    "uri": "http://pubdirecte.com/contact.php",
    "needConsent": true,
    "cookies": [],
    "js": function () {
        "use strict";
        return;
    },
    "fallback": function () {
        "use strict";
        return;
    }
};

// purechat
tarteaucitron.services.th_purechat = {
    "key": "th_purechat",
    "type": "support",
    "name": "PureChat",
    "uri": "https://www.purechat.com/privacy",
    "needConsent": true,
    "cookies": [],
    "js": function () {
        "use strict";
        return;
    },
    "fallback": function () {
        "use strict";
        return;
    }
};

// rumbletalk
tarteaucitron.services.th_rumbletalk = {
    "key": "th_rumbletalk",
    "type": "social",
    "name": "RumbleTalk",
    "needConsent": true,
    "cookies": ['AWSALB'],
    "js": function () {
        "use strict";
        return;
    },
    "fallback": function () {
        "use strict";
        return;
    }
};

// shareaholic
tarteaucitron.services.th_shareaholic = {
    "key": "th_shareaholic",
    "type": "social",
    "name": "Shareaholic",
    "uri": "https://shareaholic.com/privacy/choices",
    "needConsent": true,
    "cookies": ['__utma', '__utmb', '__utmc', '__utmz', '__utmt_Shareaholic%20Pageviews'],
    "js": function () {
        "use strict";
        return;
    },
    "fallback": function () {
        "use strict";
        return;
    }
};

// shareasale
tarteaucitron.services.th_shareasale = {
    "key": "th_shareasale",
    "type": "ads",
    "name": "ShareASale",
    "uri": "https://www.shareasale.com/PrivacyPolicy.pdf",
    "needConsent": true,
    "cookies": [],
    "js": function () {
        "use strict";
        return;
    },
    "fallback": function () {
        "use strict";
        return;
    }
};

// sharethis
tarteaucitron.services.th_sharethis = {
    "key": "th_sharethis",
    "type": "social",
    "name": "ShareThis",
    "uri": "http://www.sharethis.com/legal/privacy/",
    "needConsent": true,
    "cookies": ['__unam'],
    "js": function () {
        "use strict";
        return;
    },
    "fallback": function () {
        "use strict";
        return;
    }
};

// slideshare
tarteaucitron.services.th_slideshare = {
    "key": "th_slideshare",
    "type": "video",
    "name": "SlideShare",
    "uri": "https://www.linkedin.com/legal/privacy-policy",
    "needConsent": true,
    "cookies": [],
    "js": function () {
        "use strict";
        return;
    },
    "fallback": function () {
        "use strict";
        return;
    }
};

// statcounter
tarteaucitron.services.th_statcounter = {
    "key": "th_statcounter",
    "type": "analytic",
    "name": "StatCounter",
    "uri": "https://fr.statcounter.com/about/legal/#privacy",
    "needConsent": true,
    "cookies": ['sc_is_visitor_unique'],
    "js": function () {
        "use strict";
        return;
    },
    "fallback": function () {
        "use strict";
        return;
    }
};

// timelinejs
tarteaucitron.services.th_timelinejs = {
    "key": "th_timelinejs",
    "type": "api",
    "name": "Timeline JS",
    "uri": "http://timeline.knightlab.com/#help",
    "needConsent": true,
    "cookies": [],
    "js": function () {
        "use strict";
        return;
    },
    "fallback": function () {
        "use strict";
        return;
    }
};

// tagcommander
tarteaucitron.services.th_tagcommander = {
    "key": "th_tagcommander",
    "type": "api",
    "name": "TagCommander",
    "uri": "https://www.commandersact.com/en/privacy/",
    "needConsent": true,
    "cookies": [],
    "js": function () {
        "use strict";
        return;
    },
    "fallback": function () {
        "use strict";
        return;
    }
};

// typekit
tarteaucitron.services.th_typekit = {
    "key": "th_typekit",
    "type": "api",
    "name": "Typekit (adobe)",
    "uri": "https://www.adobe.com/privacy.html",
    "needConsent": true,
    "cookies": [],
    "js": function () {
        "use strict";
        return;
    },
    "fallback": function () {
        "use strict";
        return;
    }
};

// twenga
tarteaucitron.services.th_twenga = {
    "key": "th_twenga",
    "type": "ads",
    "name": "Twenga",
    "uri": "http://www.twenga.com/privacy.php",
    "needConsent": true,
    "cookies": [],
    "js": function () {
        "use strict";
        return;
    },
    "fallback": function () {
        "use strict";
        return;
    }
};

// twitter
tarteaucitron.services.th_twitter = {
    "key": "th_twitter",
    "type": "social",
    "name": "Twitter",
    "uri": "https://support.twitter.com/articles/20170514",
    "needConsent": true,
    "cookies": [],
    "js": function () {
        "use strict";
        return;
    },
    "fallback": function () {
        "use strict";
        return;
    }
};

// twitter embed
tarteaucitron.services.th_twitterembed = {
    "key": "th_twitterembed",
    "type": "social",
    "name": "Twitter (cards)",
    "uri": "https://support.twitter.com/articles/20170514",
    "needConsent": true,
    "cookies": [],
    "js": function () {
        "use strict";
        return;
    },
    "fallback": function () {
        "use strict";
        return;
    }
};

// twitter timeline
tarteaucitron.services.th_twittertimeline = {
    "key": "th_twittertimeline",
    "type": "social",
    "name": "Twitter (timelines)",
    "uri": "https://support.twitter.com/articles/20170514",
    "needConsent": true,
    "cookies": [],
    "js": function () {
        "use strict";
        return;
    },
    "fallback": function () {
        "use strict";
        return;
    }
};

// user voice
tarteaucitron.services.th_uservoice = {
    "key": "th_uservoice",
    "type": "support",
    "name": "UserVoice",
    "uri": "https://www.uservoice.com/privacy/",
    "needConsent": true,
    "cookies": [],
    "js": function () {
        "use strict";
        return;
    },
    "fallback": function () {
        "use strict";
        return;
    }
};

// vimeo
tarteaucitron.services.th_vimeo = {
    "key": "th_vimeo",
    "type": "video",
    "name": "Vimeo",
    "uri": "https://vimeo.com/privacy",
    "needConsent": true,
    "cookies": ['__utmt_player', '__utma', '__utmb', '__utmc', '__utmv', 'vuid', '__utmz', 'player'],
    "js": function () {
        "use strict";
        return;
    },
    "fallback": function () {
        "use strict";
        return;
    }
};

// visualrevenue
tarteaucitron.services.th_visualrevenue = {
    "key": "th_visualrevenue",
    "type": "analytic",
    "name": "VisualRevenue",
    "uri": "http://www.outbrain.com/legal/privacy-713/",
    "needConsent": true,
    "cookies": ['__vrf', '__vrm', '__vrl', '__vry', '__vru', '__vrid', '__vrz'],
    "js": function () {
        "use strict";
        return;
    },
    "fallback": function () {
        "use strict";
        return;
    }
};

// vshop
tarteaucitron.services.th_vshop = {
    "key": "th_vshop",
    "type": "ads",
    "name": "vShop",
    "uri": "http://vshop.fr/privacy-policy",
    "needConsent": true,
    "cookies": [],
    "js": function () {
        "use strict";
        return;
    },
    "fallback": function () {
        "use strict";
        return;
    }
};

// wysistat
tarteaucitron.services.th_wysistat = {
    "key": "th_wysistat",
    "type": "analytic",
    "name": "Wysistat",
    "uri": "http://wysistat.net/contact/",
    "needConsent": true,
    "cookies": ['Wysistat'],
    "js": function () {
        "use strict";
        return;
    },
    "fallback": function () {
        "use strict";
        return;
    }
};

// xiti
tarteaucitron.services.th_xiti = {
    "key": "th_xiti",
    "type": "analytic",
    "name": "Xiti",
    "uri": "https://www.atinternet.com/societe/rgpd-et-vie-privee/",
    "needConsent": true,
    "cookies": [],
    "js": function () {
        "use strict";
        return;
    },
    "fallback": function () {
        "use strict";
        return;
    }
};

// AT Internet
tarteaucitron.services.th_atinternet = {
    "key": "th_atinternet",
    "type": "analytic",
    "name": "AT Internet (privacy by design)",
    "uri": "https://www.atinternet.com/societe/rgpd-et-vie-privee/",
    "needConsent": false,
    "cookies": ['atidvisitor', 'atreman', 'atredir', 'atsession', 'atuserid'],
    "js": function () {
        "use strict";
        return;
    },
    "fallback": function () {
        "use strict";
        return;
    }
};

// AT Internet
tarteaucitron.services.th_atinternethightrack = {
    "key": "th_atinternethightrack",
    "type": "analytic",
    "name": "AT Internet",
    "uri": "https://www.atinternet.com/societe/rgpd-et-vie-privee/",
    "needConsent": true,
    "cookies": ['atidvisitor', 'atreman', 'atredir', 'atsession', 'atuserid'],
    "js": function () {
        "use strict";
        return;
    },
    "fallback": function () {
        "use strict";
        return;
    }
};

// youtube
tarteaucitron.services.th_youtube = {
    "key": "th_youtube",
    "type": "video",
    "name": "YouTube",
    "uri": "https://policies.google.com/privacy",
    "needConsent": true,
    "cookies": ['atidvisitor', 'atreman', 'atredir', 'atsession', 'atuserid'],
    "js": function () {
        "use strict";
        return;
    },
    "fallback": function () {
        "use strict";
        return;
    }
};

// youtube playlist
tarteaucitron.services.th_youtubeplaylist = {
    "key": "th_youtubeplaylist",
    "type": "video",
    "name": "YouTube (playlist)",
    "uri": "https://policies.google.com/privacy",
    "needConsent": true,
    "cookies": ['VISITOR_INFO1_LIVE', 'YSC', 'PREF', 'GEUP'],
    "js": function () {
        "use strict";
        return;
    },
    "fallback": function () {
        "use strict";
        return;
    }
};

// zopim
tarteaucitron.services.th_zopim = {
    "key": "th_zopim",
    "type": "support",
    "name": "Zopim",
    "uri": "https://www.zopim.com/privacy",
    "needConsent": true,
    "cookies": ['__zlcid', '__zprivacy'],
    "js": function () {
        "use strict";
        return;
    },
    "fallback": function () {
        "use strict";
        return;
    }
};

// xiti smartTag
tarteaucitron.services.th_xiti_smarttag = {
    "key": "th_xiti_smarttag",
    "type": "analytic",
    "name": "Xiti (SmartTag)",
    "uri": "https://www.atinternet.com/societe/protection-des-donnees/",
    "needConsent": true,
    "cookies": ["atidvisitor", "atreman", "atredir", "atsession", "atuserid", "attvtreman", "attvtsession"],
    "js": function () {
        "use strict";
        return;
    },
    "fallback": function () {
        "use strict";
        return;
    }
};

// facebook pixel
tarteaucitron.services.th_facebookpixel = {
    "key": "th_facebookpixel",
    "type": "ads",
    "name": "Facebook Pixel",
    "uri": "https://fr-fr.facebook.com/business/help/www/651294705016616",
    "needConsent": true,
    "cookies": ['datr', 'fr', 'reg_ext_ref', 'reg_fb_gate', 'reg_fb_ref', 'sb', 'wd', 'x-src'],
    "js": function () {
        "use strict";
        return;
    },
    "fallback": function () {
        "use strict";
        return;
    }
};

//Issuu
tarteaucitron.services.th_issuu = {
    "key": "th_issuu",
    "type": "other",
    "name": "Issuu",
    "uri": "https://issuu.com/legal/privacy",
    "needConsent": true,
    "cookies": ['__qca', 'iutk', 'mc'],
    "js": function () {
        "use strict";
        return;
    },
    "fallback": function () {
        "use strict";
        return;
    }
};

// webmecanik
tarteaucitron.services.th_webmecanik = {
    "key": "th_webmecanik",
    "type": "analytic",
    "name": "Webmecanik",
    "uri": "https://webmecanik.com/tos",
    "needConsent": true,
    "cookies": ['mtc_id', 'mtc_sid'],
    "js": function () {
        "use strict";
        return;
    },
    "fallback": function () {
        "use strict";
        return;
    }
};

// google analytics multiple
tarteaucitron.services.th_multiplegtag = {
    "key": "th_multiplegtag",
    "type": "analytic",
    "name": "Google Analytics (gtag.js)",
    "uri": "https://support.google.com/analytics/answer/6004245",
    "needConsent": true,
    "cookies": (function () {

        var cookies = ['_ga', '_gat', '_gid', '__utma', '__utmb', '__utmc', '__utmt', '__utmz'];

        if (tarteaucitron.user.multiplegtagUa !== undefined) {
            tarteaucitron.user.multiplegtagUa.forEach(function (ua) {
                cookies.push('_gat_gtag_' + ua.replace(/-/g, '_'));
            });
        }

        return cookies;
    })(),
    "js": function () {
        "use strict";
        return;
    },
    "fallback": function () {
        "use strict";
        return;
    }
};

// Koban
tarteaucitron.services.th_koban = {
    "key": "th_koban",
    "type": "analytic",
    "name": "Koban",
    "uri": "https://koban.cloud/tos",
    "needConsent": true,
    "cookies": ['kbntrk'],
    "js": function () {
        "use strict";
        return;
    },
    "fallback": function () {
        "use strict";
        return;
    }
};

// matomo

/*
 1. Set the following variable before the initialization :

 tarteaucitron.user.matomoId = YOUR_SITE_ID_FROM_MATOMO;
 tarteaucitron.user.matomoHost = "YOUR_MATOMO_URL"; //eg: https://stat.mydomain.com/

 2. Push the service :

 (tarteaucitron.job = tarteaucitron.job || []).push('matomo');

 3. HTML
 You don't need to add any html code, if the service is autorized, the javascript is added. otherwise no.
 */
tarteaucitron.services.th_matomo = {
    "key": "th_matomo",
    "type": "analytic",
    "name": "Matomo (privacy by design)",
    "uri": "https://matomo.org/faq/general/faq_146/",
    "needConsent": false,
    "cookies": ['_pk_ref', '_pk_cvar', '_pk_id', '_pk_ses', '_pk_hsr', 'piwik_ignore', '_pk_uid'],
    "js": function () {
        "use strict";
        if (tarteaucitron.user.matomoId === undefined) {
            return;
        }

        window._paq = window._paq || [];
        window._paq.push(["setSiteId", tarteaucitron.user.matomoId]);
        window._paq.push(["setTrackerUrl", tarteaucitron.user.matomoHost + "piwik.php"]);
        window._paq.push(["setDoNotTrack", 1]);
        window._paq.push(["trackPageView"]);
        window._paq.push(["setIgnoreClasses", ["no-tracking", "colorbox"]]);
        window._paq.push(["enableLinkTracking"]);
        window._paq.push([function () {
            var self = this;

            function getOriginalVisitorCookieTimeout() {
                var now = new Date(),
                    nowTs = Math.round(now.getTime() / 1000),
                    visitorInfo = self.getVisitorInfo();
                var createTs = parseInt(visitorInfo[2]);
                var cookieTimeout = 33696000; // 13 mois en secondes
                var originalTimeout = createTs + cookieTimeout - nowTs;
                return originalTimeout;
            }

            this.setVisitorCookieTimeout(getOriginalVisitorCookieTimeout());
        }]);

        tarteaucitron.addScript(tarteaucitron.user.matomoHost + 'piwik.js', '', '', true, 'defer', true);

        // waiting for piwik to be ready to check first party cookies
        var interval = setInterval(function () {
            if (typeof Piwik === 'undefined') return

            clearInterval(interval)

            // make piwik/matomo cookie accessible by getting tracker
            Piwik.getTracker();

            // looping throught cookies
            var theCookies = document.cookie.split(';');
            for (var i = 1; i <= theCookies.length; i++) {
                var cookie = theCookies[i - 1].split('=');
                var cookieName = cookie[0].trim();

                // if cookie starts like a piwik one, register it
                if (cookieName.indexOf('_pk_') === 0) {
                    tarteaucitron.services.th_matomo.cookies.push(cookieName);
                }
            }
        }, 100)
    }
};


tarteaucitron.services.th_matomohightrack = {
    "key": "th_matomohightrack",
    "type": "analytic",
    "name": "Matomo",
    "uri": "https://matomo.org/faq/general/faq_146/",
    "needConsent": true,
    "cookies": ['_pk_ref', '_pk_cvar', '_pk_id', '_pk_ses', '_pk_hsr', 'piwik_ignore', '_pk_uid'],
    "js": function () {
        "use strict";
        if (tarteaucitron.user.matomoId === undefined) {
            return;
        }

        window._paq = window._paq || [];
        window._paq.push(["setSiteId", tarteaucitron.user.matomoId]);
        window._paq.push(["setTrackerUrl", tarteaucitron.user.matomoHost + "piwik.php"]);
        window._paq.push(["trackPageView"]);
        window._paq.push(["setIgnoreClasses", ["no-tracking", "colorbox"]]);
        window._paq.push(["enableLinkTracking"]);
        window._paq.push([function () {
            var self = this;
        }]);

        tarteaucitron.addScript(tarteaucitron.user.matomoHost + 'piwik.js', '', '', true, 'defer', true);

        // waiting for piwik to be ready to check first party cookies
        var interval = setInterval(function () {
            if (typeof Piwik === 'undefined') return

            clearInterval(interval)
            Piwik.getTracker();

            var theCookies = document.cookie.split(';');
            for (var i = 1; i <= theCookies.length; i++) {
                var cookie = theCookies[i - 1].split('=');
                var cookieName = cookie[0].trim();

                if (cookieName.indexOf('_pk_') === 0) {
                    tarteaucitron.services.th_matomo.cookies.push(cookieName);
                }
            }
        }, 100)
    }
};

// Hotjar
/*
 1. Set the following variable before the initialization :
 tarteaucitron.user.hotjarId = YOUR_WEBSITE_ID;
 tarteaucitron.user.HotjarSv = XXXX; // Can be found in your website tracking code as "hjvs=XXXX"
 2. Push the service :
 (tarteaucitron.job = tarteaucitron.job || []).push('hotjar');
 3. HTML
 You don't need to add any html code, if the service is autorized, the javascript is added. otherwise no.
 */
tarteaucitron.services.th_hotjar = {
    "key": "th_hotjar",
    "type": "analytic",
    "name": "Hotjar",
    "uri": "https://help.hotjar.com/hc/en-us/categories/115001323967-About-Hotjar",
    "needConsent": true,
    "cookies": ["hjClosedSurveyInvites", "_hjDonePolls", "_hjMinimizedPolls", "_hjDoneTestersWidgets", "_hjMinimizedTestersWidgets", "_hjDoneSurveys", "_hjIncludedInSample", "_hjShownFeedbackMessage"],
    "js": function () {
        "use strict";
        return;
    },
    "fallback": function () {
        "use strict";
        return;
    }
};

// bing ads universal event tracking
tarteaucitron.services.th_bingads = {
    'key': 'th_bingads',
    'type': 'ads',
    'name': 'Bing Ads Universal Event Tracking',
    'uri': 'https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads',
    'needConsent': true,
    'cookies': ['_uetmsclkid', '_uetvid', '_uetsid'],
    "js": function () {
        "use strict";
        return;
    },
    "fallback": function () {
        "use strict";
        return;
    }
};

//Matterport
/*
 SERVICE INIT
 (tarteaucitron.job = tarteaucitron.job || []).push('matterport');

 HTML TAG
 <div class="matterport" matterportid="N2Q67sZUNUd" width="100%" height="550" parameters="&play=1"></div>

 DELETE IFRAME
 <iframe type="text/html" width="100%" height="550" src="https://my.matterport.com/show/?m=N2Q67sZUNUd&utm_source=hit-content&play=1" frameborder="0" allowfullscreen="allowfullscreen"></iframe>'
 */
tarteaucitron.services.th_matterport = {
    "key": "th_matterport",
    "type": "other",
    "name": "Matterport",
    "uri": "https://matterport.com/es/legal/privacy-policy/",
    "needConsent": true,
    "cookies": ['__cfduid', 'ajs_anonymous_id', 'ajs_group_id', 'ajs_user_id'],
    "js": function () {
        "use strict";
        return;
    },
    "fallback": function () {
        "use strict";
        return;
    }
};

// Adform
tarteaucitron.services.th_adform = {
    "key": "th_adform",
    "type": "ads",
    "name": "Adform",
    "uri": "https://site.adform.com/privacy-center/overview/",
    "needConsent": true,
    "cookies": [],
    "js": function () {
        "use strict";
        return;
    },
    "fallback": function () {
        "use strict";
        return;
    }
};

// Active Campaign
tarteaucitron.services.th_activecampaign = {
    "key": "th_activecampaign",
    "type": "ads",
    "name": "Active Campaign",
    "uri": "https://www.activecampaign.com/privacy-policy/",
    "needConsent": true,
    "cookies": [],
    "js": function () {
        "use strict";
        return;
    },
    "fallback": function () {
        "use strict";
        return;
    }
};

// tawk.to
tarteaucitron.services.th_tawkto = {
    "key": "th_tawkto",
    "type": "support",
    "name": "Tawk.to chat",
    "uri": "https://www.tawk.to/data-protection/",
    "needConsent": true,
    "cookies": [],
    "js": function () {
        "use strict";
        return;
    },
    "fallback": function () {
        "use strict";
        return;
    }

};

// getquanty
tarteaucitron.services.th_getquanty = {
    "key": "th_getquanty",
    "type": "analytic",
    "name": "GetQuanty",
    "uri": "https://www.getquanty.com/mentions-legales/",
    "needConsent": true,
    "cookies": ['_first_pageview', 'eqy_sessionid', 'eqy_siteid', 'cluid', 'eqy_company', 'cluid', 'gq_utm', '_jsuid'],
    "js": function () {
        "use strict";
        return;
    },
    "fallback": function () {
        "use strict";
        return;
    }
};

// emolytics
tarteaucitron.services.th_emolytics = {
    "key": "th_emolytics",
    "type": "analytic",
    "name": "Emolytics",
    "uri": "https://www.emolytics.com/main/privacy-policy.php",
    "needConsent": true,
    "cookies": ['__hssc', '__hssrc', '__hstc', '_ga', '_gid', 'hubspotutk', 'lang', 'incap_ses_', 'nlbi_', 'visid_incap_'],
    "js": function () {
        "use strict";
        return;
    },
    "fallback": function () {
        "use strict";
        return;
    }
};

// youtubeapi
tarteaucitron.services.th_youtubeapi = {
    "key": "th_youtubeapi",
    "type": "video",
    "name": "Youtube (Js API)",
    "uri": "https://policies.google.com/privacy/",
    "needConsent": true,
    "cookies": [],
    "js": function () {
        "use strict";
        return;
    },
    "fallback": function () {
        "use strict";
        return;
    }
};

// Facil'ITI
tarteaucitron.services.th_faciliti = {
    "key": "th_faciliti",
    "type": "other",
    "name": "Facil'ITI",
    "uri": "https://ws.facil-iti.com/mentions-legales.html",
    "needConsent": true,
    "cookies": ['FACIL_ITI_LS'],
    "js": function () {
        "use strict";
        return;
    },
    "fallback": function () {
        "use strict";
        return;
    }
};

// userlike
tarteaucitron.services.th_userlike = {
    "key": "th_userlike",
    "type": "support",
    "name": "Userlike",
    "uri": "https://www.userlike.com/en/terms#privacy-policy",
    "needConsent": true,
    "cookies": ['uslk_s', 'uslk_e'],
    "js": function () {
        "use strict";
        return;
    },
    "fallback": function () {
        "use strict";
        return;
    }
};

// adobeanalytics
tarteaucitron.services.th_adobeanalytics = {
    "key": "th_adobeanalytics",
    "type": "analytic",
    "name": "Adobe Analytics",
    "uri": "https://www.adobe.com/privacy/policy.html",
    "needConsent": true,
    "cookies": ['s_ecid', 's_cc', 's_sq', 's_vi', 's_fid'],
    "js": function () {
        "use strict";
        return;
    },
    "fallback": function () {
        "use strict";
        return;
    }
};

// woopra customer journey analytics
tarteaucitron.services.th_woopra = {
    'key': 'th_woopra',
    'type': 'analytic',
    'name': 'Woopra Customer Journey Analytics',
    'uri': 'https://www.woopra.com/privacy',
    'needConsent': true,
    'cookies': ['wooTracker', 'intercom-session-erbfalba', 'intercom-id-erbfalba'],
    "js": function () {
        "use strict";
        return;
    },
    "fallback": function () {
        "use strict";
        return;
    }
};