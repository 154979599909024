if($('.strate-switch').length > 0){
    var input = '#switch-search';
    $(input).on('change', function(){

        var target = false;
        if($(input+':checked').length > 0){
            target = $('label a:nth-of-type(2)', '.strate-switch').attr('href');
        }else if($(input+':not(:checked)').length > 0){
            target = $('label a:nth-of-type(1)', '.strate-switch').attr('href');
        }

        if(target){
            window.location.replace(target);
        }
    });
}