function initWebsiteSearch(){

    var $input = $('.overlay-search input');
    var $buttonExplore = $('[data-overlay-open="overlay-search"]');
    var $searchWrapper = $('.container-resultats');
    var timeoutAlgoliaClass = null;

    // Ajoute une class à la balise form pour annuler le curseur qui clignotte
    $input.focusin(function () {
        $(this).parents('form:first').addClass('focused');
    });

    $buttonExplore.on('click', function () {
        setTimeout(function () {
            $input.focus();
            $('.overlay-search form').addClass('focused');
        }, 500);
    });

    // var iScrollBarAlgolia = null;
    // if (hasIscroll()) {
    //     th_overlay.addCallbackOpen(function (overlayId) {
    //         $input.focus();
    //
    //     });
    // }

    setTimeout(function(){
        // init de algolia
        thuriaSearch.init(
            algolia.application_id,
            algolia.search_api_key,
            ['wp_posts_page','wp_posts_sejours','wp_posts_experiences','wp_posts_tourinsoft'],
            $('#input-search-algolia').get(0),
            $('#algolia-results').get(0),
            $('#overlay-search').get(0)
        );

        thuriaSearch.setLanguage(algolia_language.fulllang);

    },1000);
}

if ($('.overlay-search').length > 0) {
    initWebsiteSearch();
}