
(function () {


    // On calcule le width de chaque ancre afin de faire la somme et savoir quel est la vraie largeur des ancres.
    // Ensuite si le width des ancres est inférieur à la taille du header, on cache la flèche et le overflow

    var slider = $('.nav-alt .th-slider:not(.no-autoload)');

    setTimeout(function() {
        if(slider) {
            var totalSliderWidth  = $(slider).outerWidth();
            var children = $(slider).children();

            if (children) {
                var totalChildrenWidth  = 0;

                children.each(function () {
                    totalChildrenWidth += $(this).outerWidth(true);     // taille des items marges inclues
                });

                if (totalChildrenWidth >= totalSliderWidth) {
                    initSlider(slider[0]);
                }
            }
        }
    }, 600);    // délai pour avoir le bon calcul des largeurs (fonts chargés...)



    function initSlider(slider) {
        var parent = slider.parentNode;
        while (!parent.className.match(/th-slider-wrapper/)) {
            parent = parent.parentNode;
        }

        var s = new thSlider(slider, {
            draggable: true,
            scrollListener: true,
            scrollModeMaxWidth: '1199',
        });

    }

})();

